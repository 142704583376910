<template>
  <div class="oldBrand-add">
    <GlobalChunk :icon="DetailIMG" :title="titleDetail" :padding="[32, 0, 95, 55]">
      <el-row>
        <el-col :span="10">
          <GlobalForm
            :init-data="initData"
            :form-rules="formRules"
            :form-item-list="formItemList"
            @handleConfirm="handleConfirm"
            confirmBtnName="提交"
          >
            <el-form-item class="el_from" label="职员角色:" slot="operation" :required="true">
              <el-select
                v-model="initData.roleName"
                placeholder="请选择（如无角色请先创建角色）"
                clearable
                @change="handleChange"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.roleName"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-button type="text" @click="$router.push('/systemManage/RoleAdd')">添加角色</el-button>
            </el-form-item>
            
            <el-form-item class="el_from" label="登录账号:" slot="operation" :required="true">
              <el-input
                v-model="initData.userAccount"
                :disabled="ShowUserAccount"
                placeholder="输入登录账号(6-16位)"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item class="el_from" label="登录密码:" slot="operation" :required="true">
              <el-input
                type="password"
                v-model="initData.userPassword"
                placeholder="******"
                clearable
              ></el-input>
            </el-form-item>
            <el-button slot="btnSlot" @click="FromBack">返回</el-button>
          </GlobalForm>
        </el-col>
      </el-row>
    </GlobalChunk>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
export default {
  name: "oldBrand-add",
  data() {
    return {
      titleDetail:"",
      DetailIMG:"",
      StaffFormData: [],
      ShowUserAccount: false,
      roleId: "",
      options: [],
      initData: {
        userName: "",
        roleName: "",
        userPhone: "",
        userAccount: "",
        userPassword: '',
        entryTime: "",
        userSex: "",
        userImg: "",
        readonlyUser: true
      },
      formItemList: [
        {
          key: "userName",
          type: "input",
          labelName: "职员姓名",
          placeholder: "输入职员姓名（10字以内的中文汉字或英文字母）"
        },
        { slotName: "operationSelect" },
        {
          key: "userPhone",
          type: "input",
          labelName: "联系电话",
          placeholder: "输入联系电话"
        },
        // {
        //   key: "userPassword",
        //   type: "input",
        //   labelName: "登录密码",
        //   placeholder: "******",
        //   password: "password"
        // },
        { slotName: "operation" },
        {
          key: "entryTime",
          type: "date",
          labelName: "入职时间",
          placeholder: "输入入职时间",
          valueFormat: "timestamp"
        },
        {
          key: "userSex",
          type: "radio",
          labelName: "性别",
          option: [
            { label: "女", value: "00" },
            { label: "男", value: "01" }
          ]
        },
        {
          key: "userImg",
          type: "upload",
          labelName: "头像图标",
          placeholder: "最多支持上传1张图片，大小不超过5M，格式为JPG或PNG"
        }
      ],

      formRules: {
        userName: [
          {
            required: true,
            trigger: "blur",
            message: "输入职员姓名（10字以内的中文汉字或英文字母）",
            max:10
          }
        ],
        entryTime: [
          {
            required: true,
            trigger: "blur",
            message: "请输入入职时间"
          }
        ],
        userSex: [
          {
            required: true,
            trigger: "blur",
            message: "请选择性别"
          }
        ],
        userImg: [
          {
            required: true,
            trigger: "blur",
            message: "请上传头像图标"
          }
        ],
        userPhone: [
          {
            validator: formValidateMobile,
            required: true,
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    this.selectRole();
    if (this.$route.query.type === "edit") {
      this.ShowUserAccount = true;
    } else {
      this.ShowUserAccount = false;
    }
  },
  mounted() {
    if (this.$route.query.type === "edit") {
      this.titleDetail = '编辑职员'
      this.DetailIMG = 'edit'
      _api.StaffInfo({ id: this.$route.query.id }).then(res => {
        if (res.code === 1) {
          this.StaffFormData = res.data;
          this.initData.userName = this.StaffFormData.userName;
          this.initData.roleName = this.StaffFormData.roleName;
          this.initData.userPhone = this.StaffFormData.userPhone;
          this.initData.userAccount = this.StaffFormData.userAccount;
          // this.initData.userPassword = this.StaffFormData.userPassword;
          this.initData.entryTime = new Date(this.StaffFormData.entryTime);
          this.initData.userSex = this.StaffFormData.userSex;
          this.initData.userImg = this.StaffFormData.userImg;
          this.roleId = this.StaffFormData.roleId;
        }
      });
    }else{
      this.titleDetail = '添加职员'
      this.DetailIMG = 'add'
    }
  },

  methods: {
    handleChange(val) {
      console.log(val);
      this.roleId = val;
    },
    selectRole() {
      _api.RoleList().then(res => {
        if (res.code === 1) {
          this.options = res.data;
        }
      });
    },
    handleConfirm(data, cd) {
      cd();
      const dto = {
        userName: data.userName,
        roleId: this.roleId,
        userPhone: data.userPhone,
        userAccount: data.userAccount,
        userPassword: data.userPassword,
        entryTime: data.entryTime,
        userSex: data.userSex,
        userImg: data.userImg,
        id: this.StaffFormData.id
      };
      if (this.initData.userAccount === ""||this.initData.userAccount.length>16||this.initData.userAccount.length<6) {
        this.$message.error("请输入6-16位登录账号");
      } 
      else if(this.ShowUserAccount === false&&this.initData.userPassword === ""){
        this.$message.error("请输入6-16位登录密码");
      }
      else if (this.roleId === "" && this.initData.roleName === "") {
        this.$message.error("请选择角色");
      } else {
        _api.StaffSave(dto).then(res => {
          if (res.code === 1) {
            this.$router.push({ path: "/systemManage/StaffManage" });
            this.$message({
              message: res.data,
              type: "success"
            });
          } else {
            this.$message({
              message: res.msg,
              type: "error"
            });
          }
        });
      }
    },
    FromBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.oldBrand-add {
  .el_from {
    margin-left: -120px;
    // /deep/.el-select > .el-input{
    //   width: 500px!important;
    // }
  }
}
</style>
